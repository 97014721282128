import React from 'react';
import './Content.css';

export default class Content extends React.Component {
  render() {
    return (
      <>
        <h1 className={this.props.lightText ? 'content-heading' : 'content-heading dark'}>
          {this.props.headline}
        </h1>
        <h5 className={this.props.lightText ? 'content-subheading' : 'content-heading dark'}>
          {this.props.description}
        </h5>
        <p style={{whiteSpace: 'pre-wrap'}}
          className={
            this.props.lightTextDesc
              ? 'content-subtitle'
              : 'content-subtitle dark'
          }
        >
          {this.props.text}
        </p>
      </>
    );
  }
}
