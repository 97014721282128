import React from 'react';
import {Helmet} from "react-helmet";
import ContentSection from '../../ContentSection';
import { privacyObjOne } from './Data';

export default class Privacy extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content="Datenschutzrechtliche Informationen über das Steuerbüro Birgel" />
          <meta name="title" content="Datenschutzerklärung - Steuerbüro Birgel"/>
          <title>Datenschutzerklärung - Steuerbüro Birgel</title>
        </Helmet>
        <ContentSection {...privacyObjOne} />
      </>
    );
  }
}