import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Steuerbüro Birgel</h2>
            <Link to='/'>Startseite</Link>
            <Link to='/kontakt'>Kontakt</Link>
            <Link to='/impressum'>Impressum</Link>
            <Link to='/datenschutz'>Datenschutzerklärung</Link>
          </div>

          <div className='footer-link-items'>
            <h2>Anschrift</h2>
            <p>Steuerberater</p>
            <p>Harald Birgel</p>
            <p>Talstraße 3-5</p>
            <p>34253 Lohfelden</p>
          </div>
          <div className='footer-link-items'>
            <h2>Kontakt</h2>
            <p>E-Mail: <a href="mailto:info@birgel.net">info@birgel.net</a></p>
            <p>Tel: <a href="tel:+49-561-978-990">+49-561-978-990</a></p>
            <p>Mobil: <a href="tel:+49-177-778-9912">+49-177-778-9912</a></p>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            <img src='images/logo.png' alt="Logo" className='navbar-icon' />
              IHR STEUERBERATER
            </Link>
          </div>
          <small className='website-rights'>STEUERBÜRO BIRGEL © {new Date().getFullYear()}</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
