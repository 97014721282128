import React from 'react';
import {Helmet} from "react-helmet";
import LeafletMap from '../../LeafletMap';

export default class Contact extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content="Nehmen Sie Kontakt mit Steuerbüro Birgel auf: Hier finden Sie Anschrift, eine Karte, E-Mail Adresse sowie unsere Telefonnummern." />
          <meta name="title" content="Kontakt - Steuerbüro Birgel"/>
          <title>Kontakt - Steuerbüro Birgel</title>
        </Helmet>
        <LeafletMap/>
      </>
    );
  }
}
