import React from 'react';
import {Helmet} from "react-helmet";
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
export default class Home extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content="Willkommen bei Ihrem Steuerberater in Lohfelden! Wir sind eine kleine erfahrene Steuerkanzlei in Lohfelden, die bereits in der zweiten Steuerberater Generation geführt wird. Seit mehr als 50 Jahren betreuen wir vor allem Mandate in Kassel und Umgebung. Durch unsere digitalen Prozesse können wir aber deutschlandweit Mandate betreuen, was immer mehr Zuspruch findet." />
          <meta name="title" content="Steuerbüro Birgel"/>
          <title>Steuerbüro Birgel</title>
        </Helmet>
        <HeroSection {...homeObjOne} />
        <HeroSection {...homeObjTwo} />
        <HeroSection {...homeObjThree} />
        <HeroSection {...homeObjFour} />
      </>
    );
  }
}
