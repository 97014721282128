import React from 'react';
import Content from './Content';

import './ContentSection.css';

export default class ContentSection extends React.Component {
  render() {
    return (
      <>
        <div
          className={this.props.lightBg ? 'content-section' : 'content-section darkBg'}
        >
          <div className='container'>
            <div
              className='row content-row'
              style={{
                display: 'flex',
                flexDirection: this.props.imgStart === 'start' ? 'row-reverse' : 'row'
              }}
            >
              <div className='content-col'>
                <div className='content-text-wrapper'>
                  <div className='top-line'>{this.props.topLine}</div>
                  {
                    this.props.contents.map((content, index) => (
                      <Content key={index} {...content} />
                    ))
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
