import React from 'react';
import './App.css';
import Home from './components/pages/HomePage/Home';
import Impress from './components/pages/Impress/Impress';
import Services from './components/pages/Services/Services';
import Privacy from './components/pages/Privacy/Privacy';
import Contact from './components/pages/Contact/Contact';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/pages/Footer.js/Footer';
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/impressum' component={Impress} />
        <Route path='/leistungen' component={Services} />
        <Route path='/datenschutz' component={Privacy} />
        <Route path='/kontakt' component={Contact} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
