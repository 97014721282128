import React from 'react';
import {Helmet} from "react-helmet";
import ContentSection from '../../ContentSection';
import { impressObjOne } from './Data';

export default class Impress extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content="Allgemeine und Rechtliche Informationen über das Steuerbüro Birgel" />
          <meta name="title" content="Impressum - Steuerbüro Birgel"/>
          <title>Impressum - Steuerbüro Birgel</title>
        </Helmet>
        <ContentSection {...impressObjOne} />
      </>
    );
  }
}
