export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: 'Willkommen bei Ihrem Steuerberater in Lohfelden',
  description:
    'Wir sind eine kleine erfahrene Steuerkanzlei in Lohfelden, die bereits in der zweiten Steuerberater Generation geführt wird. Seit mehr als 50 Jahren betreuen wir vor allem Mandate in Kassel und Umgebung.\nDurch unsere digitalen Prozesse können wir aber deutschlandweit Mandate betreuen, was immer mehr Zuspruch findet.',
  buttonLabel: 'Mehr erfahren',
  buttonLink: '/leistungen',
  imgStart: '',
  img: 'images/harald.jpg',
  alt: 'Steuererklärung'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'Unser Fokus',
  description:
    'Unsere Steuerkanzlei kümmert sich ganzheitlich um Ihre steuerlichen Angelegenheiten. Von der Einkommensteuererklärung bis zum Jahresabschluss für Firmen vertreten wir Sie in allen Steuerfragen. Als Steuerberater ist es unser Bestreben, Ihnen steuerlich und wirtschaftlich zu einem optimalen Ergebnis zu verhelfen.',
  buttonLabel: 'Mehr erfahren',
  buttonLink: '/leistungen',
  imgStart: 'start',
  img: 'images/tax_declaration.jpg',
  alt: 'Steuererklärung'
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: 'Unsere Philosophie',
  description:
    'Wir sind ein kleines Team mit der Philosophie, fast immer persönlich für unsere Mandanten erreichbar zu sein.',
  buttonLabel: 'Mehr erfahren',
  buttonLink: '/leistungen',
  imgStart: '',
  img: 'images/handshake.jpg',
  alt: 'Steuererklärung'
};

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'Transparenz',
  description:
    'Auf unserer Internetseite haben wir Ihnen einige wichtige Informationen für Privatmandate und Unternehmer zusammengestellt.\n\nWir freuen uns über Ihren Besuch, sehen Sie sich in Ruhe um. Wenn Sie Fragen haben, rufen Sie uns einfach an.',
  buttonLabel: 'Kontakt',
  buttonLink: '/kontakt',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
