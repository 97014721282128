import React from 'react';
import './LeafletMap.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet'

const position = [51.2774058, 9.5337141]

const newicon = new L.icon({
  iconUrl: "images/icon.png",
  iconSize: [30, 30]
});
        
export default class LeafletMap extends React.Component {
  render() {
    return (
      <>
        <div className="leaflet-container">
          <MapContainer center={position} zoom={14} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={newicon}>
              <Popup>
                <h2>Steuerbüro Harald Birgel</h2>
                <p>
                Talstraße 3-5<br/>
                34253 Lohfelden
                </p>
                <p>
                E-Mail: <a href="mailto:info@birgel.net">info@birgel.net</a><br/>
                Tel: <a href="tel:+49-561-978-990">+49-561-978-990</a><br/>
                Mobil: <a href="tel:+49-177-778-9912">+49-177-778-9912</a><br/>
                </p>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </>
    );
  }
}
