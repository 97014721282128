import React from 'react';
import {Helmet} from "react-helmet";
import ContentSection from '../../ContentSection';
import { serviceObjOne, serviceObjTwo } from './Data';

export default class Services extends React.Component {
  render() {
    return (
      <>
      
        <Helmet>
          <meta name="description" content="Lohnt sich ein Steuerberater für Arbeitnehmer? Viele Angestellte zerbrechen sich häufig den Kopf nach dem Sinn oder Unsinn eines Steuerberaters, der sie bei ihrer Einkommensteuer-Erklärung unterstützt. Wann lohnt es sich, professionelle Steuerhilfe für Arbeitnehmer in Anspruch zu nehmen?" data-react-helmet="true" />
          <meta name="title" content="Leistungen - Steuerbüro Birgel"/>
          <title>Leistungen - Steuerbüro Birgel</title>
        </Helmet>
        <ContentSection {...serviceObjOne} />
        <ContentSection {...serviceObjTwo} />
      </>
    );
  }
}