export const privacyObjOne = {
  lightBg: false,
  topLine: 'Datenschutzerklärung',
  imgStart: 'start',
  contents: [
    {
      lightText: true,
      lightTextDesc: true,
      headline: 'Unsere Datenschutzerklärung',
      text: 'Wir freuen uns sehr über Ihr Interesse an unseren Dienstleistungen. Datenschutz hat einen besonders hohen Stellenwert für unser Steuerbüro.\n\nUnser Datenschutzverantwortlicher ist:\nHarald Birgel\nTalstraße 2-5\n34253 Lohfelden\nTel: +49-561-978-990',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Besuch der Webseite',
      text: 'Beim Besuch der Webseite wird aus technischen Gründen Ihre IP-Adresse und die angefragte Seite erfasst, damit Ihnen die Inhalte der richtigen Seite angezeigt werden können. Wir stützen dies auf Art. 6 Abs. 1 UAbs. 1 Buchstabe b DSGVO.\n\nZu jeder Anfrage (HTTP-Request) werden weiterhin die IP-Adresse, die Adresse der aufgerufenen Seite, ihre Größe, ein Zeitstempel, der Reiferer (also die Adresse der Seite, von der Sie kamen), der Erfolgsstatus der Anfrage (etwa 404 für „nicht gefunden“), Browser-Name und -Version und das Betriebssystem einschließlich Versionsnummer zur Erzeugung anonymisierter Zugriffstatistiken — keine Profilbildung! — und für die Erleichterung der Verhinderung von Missbrauch der Webseite in den Serverlogfiles für 7 Tage abgespeichert und danach unwiderruflich gelöscht. Rechtsgrundlage hierfür bildet Art. 6 Abs. 1 UAbs. 1 Buchstabe f DSGVO. Von der Löschung ausgenommen sind Daten, die zur Durchsetzung rechtlicher Ansprüche benötigt werden.\n\nDie in den Logfiles erfassten Daten werden nicht an Dritte übermittelt, sofern es nicht zur Durchsetzung eines rechtlichen Anspruchs erforderlich ist oder durch die Strafverfolgungsbehörden angeordnet wird. In diesen Fällen werden die Daten an die entsprechenden Justizbehörden und unsere Anwälte übermittelt.\n\nDie Bereitstellung der o.g. personenbezogenen Daten ist nicht gesetzlich oder vertraglich vorgeschrieben und Sie sind zur Bereitstellung dieser personenbezogenen Daten nicht verpflichtet. Die Unterdrückung von anderen Daten als der IP-Adresse bei Besuch der Webseite hat keine Auswirkungen auf die Benutzbarkeit der Webseite. Für einen Vertragsschluss ist die Bereitstellung der genannten Daten nicht erforderlich.',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Auftragsverarbeitung',
      text: 'Der Server, auf dem diese Webseite und unser E-Mail-Dienst betrieben werden, ist von einem Hoster gemietet, der uns gegenüber weisungsgebunden ist. Über die Webseite und per E-Mail durchgeführte Datenerhebungs- und Verarbeitungsprozesse werden nicht von uns direkt, sondern von diesem Hoster für uns in unserem Auftrag durchgeführt. Hierbei handelt es sich um eine Auftragsverarbeitung gem. Art. 28 DSGVO.',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Ihre Rechte',
      text: 'Sie können gem. Art. 15 DSGVO von uns eine Bestätigung darüber verlangen, ob wir personenbezogene Daten zu Ihnen verarbeiten. Art. 15 DSGVO gibt Ihnen weiterhin das Recht, Auskunft über diese Daten sowie die weiteren in Art. 15 DSGVO genannten Informationen zu verlangen.\n\nIhnen steht ferner gem. Art. 16 und Art. 17 DSGVO ein Berichtungs- und Löschrecht im Bezug auf Ihre von uns verarbeiteten personenbezogenen Daten zu. Nach Maßgabe von Art. 18 DSGVO kann ein Recht auf Einschränkung der Datenverarbeitung geltend gemacht werden.\n\nGegen die auf Art. 6 Absatz 1 UAbs. 1 Buchstabe f gestützten Verarbeitungsvorgänge steht Ihnen gem. Art. 21 DSGVO ein Widerspruchsrecht zu.\n\nNach Art. 20 DSGVO können Sie verlangen, eine Kopie Ihrer personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie können auch verlangen, dass die Daten direkt an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.\n\nBeschwerden können bei den Aufsichtsbehörden gem. Art. 77 DSGVO geltend gemacht werden.\n\nLandesbeauftragte für Datenschutz und Informationsfreiheit Hessen:\nGustav-Stresemann-Ring 1\n65189 Wiesbaden\nTelefon: 0611-1408 0\nE-Mail: poststelle@datenschutz.hessen.de',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Cookies',
      text: 'Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.\n\nDie meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.\n\nSie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Browser Plugin',
      text: 'Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Auskunft, Löschung, Sperrung',
      text: 'Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.\n\nDiese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.\n\nBei Bedarf wenden Sie sich bitte an:\nHarald Birgel\nTalstraße 2-5\n34253 Lohfelden\nTel: +49-561-978-990',
    }
  ]
};