export const impressObjOne = {
  lightBg: false,
  topLine: 'Impressum',
  imgStart: 'start',
  contents: [
    {
      lightText: true,
      lightTextDesc: true,
      headline: 'Allgemeine Informationen',
      text: 'Harald Birgel - Steuerberater\nTalstraße 2-5\n34253 Lohfelden\nTel: +49-561-978-990',
    },
    {
      lightText: true,
      lightTextDesc: true,
      headline: 'Rechtliche Informationen',
      text: 'Inhaber: Herr Harald Birgel\nRechtsform: Einzelunternehmen\nAufsichtsbehörde: Steuerberaterkammer Hessen\n\nKammer: Steuerberaterkammer Hessen, Frankfurt a.M.\nGesetzliche Berufsbezeichnung: Steuerberater, Deutschland\nBerufsrechtlichen Regelungen: Steuerberatergesetz (StBerG – www.bstbk.de)\nUmsatzsteuer ID: \n\nOnline-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter https://ec.europa.eu/consumers/odr/ finden. Darüber hinaus nimmt unser Betrieb an einem Verbraucherstreitigkeitsverfahren nicht teil.',
    }
  ]
};