export const serviceObjOne = {
  lightBg: false,
  topLine: 'Steuerberater für Angestellte oder Rentner',
  imgStart: 'start',
  contents: [
    {
      lightText: true,
      lightTextDesc: true,
      headline: 'Lohnt sich ein Steuerberater für Arbeitnehmer?',
      description:
        'Viele Angestellte zerbrechen sich häufig den Kopf nach dem Sinn oder Unsinn eines Steuerberaters, der sie bei ihrer Einkommensteuer-Erklärung unterstützt. Wann lohnt es sich, professionelle Steuerhilfe für Arbeitnehmer in Anspruch zu nehmen?',
      text: 'Angestellte genießen das Privileg oder den Fluch (je nach individueller Anschauung), dass ihre Einkommenssteuer bereits in Form der Lohnsteuer vom Arbeitgeber vom Gehalt einbehalten und an den Fiskus abgeführt wird. Es ist ein Privileg, da dies der Arbeitgeber unentgeltlich für den Arbeitnehmer übernimmt und sich dieser um nichts kümmern muss. Ein Fluch ist es jedoch, da der Arbeitnehmer auf die Höhe des Einbehaltes keinen direkten Einfluss hat.\n\nEntsprechend begrenzt sind auch die Möglichkeiten der steuerlichen Gestaltung.\n\nExistieren jedoch sehr weite Wege zwischen Wohnung und Arbeitsstätte, eine doppelte Haushaltsführung,  kostenspielige Fortbildungen oder ein Arbeitszimmer, kann sich eine steuerliche Beratung rentieren.',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Abgabepflicht einer Einkommensteuer-Erklärung wegen Kurzarbeitergeld und für manche Rentner, auch wenn es sich nicht lohnt',
      text: 'Für das Jahr 2020 werden aufgrund von Kurzarbeitergeld (KUG) viele Arbeitnehmer erstmalig eine Einkommensteuer-Erklärung abgeben müssen. Hier kann es auch zu Einkommensteuernachzahlungen kommen, wenn man den Steuernachteil durch das Kurzarbeitergeld nicht durch andere Aufwendungen ausgleichen kann.\n\nDurch jährlich steigende Renten und einen prozentual zunehmenden steuerpflichtigen Teil der Rente bei Neurentnern kommen immer mehr Rentner in den zweifelhaften „Genuss“, eine Einkommensteuererklärung abgeben zu müssen. Mangels automatischem Steuerabzug wie bei der Lohnsteuer kann es auch hier zu Einkommensteuernachzahlungen kommen.',
    },
    {
      lightText: true,
      lightTextDesc: true,
      description:
        'Ist ein Steuerberater nicht sehr teuer?',
      text: 'Das Honorar eines Steuerberaters richtet sich grundsätzlich nach der Steuerberatergebührenverordnung. Die Vergütungen werden als Wertgebühr oder als Zeitgebühr erhoben. Es existieren Maximalgebühren, jedoch auch Mindestgebühren. Die Höhe des Honorars hängt von den Werten, sowie vom anfallenden Arbeitsaufwand ab.\n\nFür einfache, jährlich anfallende Steuererklärungen ist auch die Vereinbarung einer Pauschalvergütung möglich. Analog zu einem Lohnsteuerhilfeverein, nur dass hier ein „echter“ Steuerberater die Steuererklärung erstellt.\n\nSie werden überrascht sein, dass meine Pauschalvergütungen auf einem ähnlichen Niveau wie dem der Lohnsteuerhilfevereine liegen.\n\nGern erwarten wir Ihre Kontaktaufnahme zwecks Terminvereinbarung für eine Beratung.',
    }
  ]
};

export const serviceObjTwo = {
  lightBg: true,
  topLine: 'Steuerberater für Firmen',
  imgStart: 'start',
  contents: [
    {
      lightText: false,
      lightTextDesc: false,
      headline: 'Steuerberater für Firmen',
      text: 'Wenn Sie ein gewerbliches oder freiberufliches Unternehmen bereits führen oder beabsichtigen, es zu tun, unterstützen wir Sie gerne bei der Bearbeitung Ihrer Finanz- und Lohnbuchhaltung, sowie bei der Erstellung der Jahresabschlüsse (Bilanz) oder Einnahmeüberschussrechnungen (EÜR).\n\nNatürlich gehören auch die damit verbundenen Jahressteuererklärungen wie die Umsatzsteuererklärung oder die Gewerbesteuererklärung dazu.',
    }
  ]
};
